import React, { createContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import useConfig from "../hooks/useConfig";

const cookies = new Cookies();

type userData = {
    status: string,
    data: {
        username: string,
    } | undefined
}

type ContextType = {
    userData: userData|undefined,
    setUserData: (user: userData) => void;
    refreshUserInfo: number,
    setRefreshUserInfo: (timestamp: number) => void;
};

export const UserContext = createContext<ContextType>({
    userData: undefined,
    setUserData: (userData: userData) => {},
    refreshUserInfo: Date.now(),
    setRefreshUserInfo: (timestamp: number) => {},
});

type providerProps = {
    children: React.ReactNode;
};

const UserProvider: React.FC<providerProps> = ({ children }) => {
    const config = useConfig();
    const [userData, setUserData] = useState<userData|undefined>();
    const [ refreshUserInfo, setRefreshUserInfo ] = useState(Date.now());

    function closeSession() {
        console.log('closing session');
        cookies.remove(config.app.COOKIE_USER);
        window.location.replace(config.app.BACKEND_URL);
    };

    function updaterUserData() {
        fetch(`${config.app.BACKEND_URL}/auth/myaccount`, {
            method: 'get',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            console.log('useContext resp:', data);

            if (data.error) { throw new Error(data.error) }      

            if (data.Message) {
                if (data.Message === 'User is not authorized to access this resource with an explicit deny') {
                    closeSession();
                }
            } else if (data.message) {
                if (data.message === 'Session is expired' || data.message.match('User does not exist')) {
                    closeSession();
                }
            }

            const { username } = data;

            setUserData({
                status: 'logged',
                data: {
                    username
                }
            });

        })
        .catch((error) => {
            console.log('useContext Catch:', error);
            closeSession();
        });
    }

    useEffect(() => {

        if (cookies.get(config.app.COOKIE_USER)) {
            setUserData({
                status: 'loading',
                data: undefined,
            });

            updaterUserData()
        }

    }, [refreshUserInfo])

    return (
        <UserContext.Provider value={{ userData, setUserData, refreshUserInfo, setRefreshUserInfo  }}>
            { children }
        </UserContext.Provider>
    );
};

export default UserProvider;